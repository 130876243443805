// extracted by mini-css-extract-plugin
export var article__bg = "alilo-module--article__bg--8429e";
export var box__descr = "alilo-module--box__descr--79f34";
export var box__mobile__descr = "alilo-module--box__mobile__descr--64c0e";
export var box__wrapper = "alilo-module--box__wrapper--175c5";
export var container = "alilo-module--container--8d9fe";
export var divider = "alilo-module--divider--8e4b3";
export var firstText = "alilo-module--firstText--11e11";
export var last__text = "alilo-module--last__text--e3c3d";
export var mobile__img = "alilo-module--mobile__img--254da";
export var sectionTitle__img = "alilo-module--sectionTitle__img--fa324";
export var sectionTitle__title = "alilo-module--sectionTitle__title--d7078";
export var section__Alilo2__img = "alilo-module--section__Alilo2__img--1e537";
export var section__Alilo__img = "alilo-module--section__Alilo__img--16653";
export var section__descr = "alilo-module--section__descr--5eb6d";
export var section__img = "alilo-module--section__img--16232";
export var section__list = "alilo-module--section__list--61063";
export var section__text = "alilo-module--section__text--e0258";
export var secton__list__item = "alilo-module--secton__list__item--7c5d5";
export var text = "alilo-module--text--dda76";
export var title = "alilo-module--title--d85ba";
export var wrapper = "alilo-module--wrapper--bff5a";