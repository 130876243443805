import React from "react"
import Layout from "../../components/layout"
import Form from "../../components/Form"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as styles from "./alilo.module.css"

import AliloImage from "../../assets/images/alilo/AliloImage.jpg"
import AliloImage2 from "../../assets/images/alilo/AliloImage-2.jpg"
import AliloImage3 from "../../assets/images/alilo/AliloImage-3.jpg"
import AliloImage4 from "../../assets/images/alilo/AliloImage-4.jpg"
import Bear from "../../assets/images/alilo/teddy-bear.svg"

const Alilo = ({ pageContext }) => {
  const { t } = useTranslation()
  return (
    <Layout>
      <div className={styles.wrapper}>
        <section>
          <div className={styles.container}>
            <Breadcrumbs pageContext={pageContext} title="Alilo" />
            <h2 className={`${styles.title} ${styles.sectionTitle__title}`}>
              Снизили CPO на&nbsp;30% с&nbsp;помощью внешнего трафика
              и&nbsp;локальных сегментов на&nbsp;Wildberries
            </h2>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.sectionTitle__img} `}
            src={AliloImage}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.sectionTitle__img}
              src={AliloImage}
              alt="LibredermImage"
            />
            <p className={styles.text}>
              История о&nbsp;том, как мы&nbsp;помогли бренду интерактивных
              игрушек для малышей&nbsp;&mdash; Alilo увеличить продажи детских
              товаров и&nbsp;снизить CPO на&nbsp;30%.
            </p>
            <p className={styles.text}>
              Внутренние инструменты маркетплейсов не&nbsp;дают большого притока
              новой аудитории и&nbsp;роста продаж. Если запускать внешний трафик
              через контекстную или таргетированную рекламу,
              то&nbsp;в&nbsp;настройках можно выбрать только широкую категорию
              для Alilo&nbsp;&mdash; &laquo;детские товары&raquo;. Такую рекламу
              нельзя самостоятельно обновить и&nbsp;со&nbsp;временем
              органический трафик &laquo;перетирается&raquo;.
            </p>
            <p className={styles.text}>
              Бренду нужен был глоток новой целевой аудитории, именно
              с&nbsp;такой задачей обратились к&nbsp;нам бренд Alilo. Нам
              предстояло снизить CPO и&nbsp;увеличить продажи.
            </p>
            <p className={styles.text}>
              Мы&nbsp;сделали акцент на&nbsp;Wildberries, так как это наиболее
              релевантная площадка для продвижения детских товаров по&nbsp;опыту
              тестирования ритейлеров. Перед запуском проверили сток товара
              и&nbsp;карточки на&nbsp;маркетплейсе. Дальше приступили
              к&nbsp;сборке сегментов новой аудитории при помощи своей CDP.
              Определили и&nbsp;выделили широкий сегмент покупателей
              на&nbsp;основе транзакций в&nbsp;категории интерактивные игрушки.
            </p>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.section__img}`}
            src={AliloImage2}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.section__img}
              src={AliloImage2}
              alt="LibredermImage"
            />
            <p className={styles.text}>
              Запускали сезонные акции, обновляли креативы. Лучше всего
              отрабатывала связка: статичные баннеры + анимация. А&nbsp;также
              вместе с&nbsp;командой бренда транслировали покупателям скидку
              от&nbsp;30&nbsp;до&nbsp;54%.
            </p>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.section__Alilo__img}`}
            src={AliloImage3}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.section__Alilo__img}
              src={AliloImage3}
              alt="LibredermImage"
            />
            <p className={styles.text}>
              Загрузили сегменты в&nbsp;рекламные кабинеты Яндекс РСЯ,
              VK&nbsp;Реклама и&nbsp;Telegram Ads и&nbsp;запустили внешний
              трафик на&nbsp;подборки Wildberries.
            </p>
            <p className={styles.text}>
              На&nbsp;протяжении месяца мы&nbsp;видели, что CPO снижалось,
              но&nbsp;не&nbsp;так сильно, как хотелось&nbsp;бы.
              Мы&nbsp;тестировали разные сегменты аудитории и&nbsp;получили
              наибольшую эффективность от&nbsp;узких точечных категорий:
              ограничили возраст малышей до&nbsp;новорождённых и&nbsp;подключили
              аудиторию покупателей сопутствующий товаров (детская гигиена,
              подгузники, детское питание и&nbsp;т.&nbsp;д. ).
            </p>
            <p className={styles.text}>
              Увидели снижение CPO на&nbsp;30% в&nbsp;течение тестового периода.
            </p>
          </div>
          <img
            className={`${styles.mobile__img} ${styles.section__Alilo2__img}`}
            src={AliloImage4}
            alt="LibredermImage"
          />
          <div className={styles.container}>
            <img
              className={styles.section__Alilo2__img}
              src={AliloImage4}
              alt="LibredermImage"
            />
          </div>
        </section>
        <article className={styles.article__bg}>
          <div className={styles.divider}></div>
          <div className={styles.box__wrapper}>
            <div className={styles.box__left}>
              <img className={styles.box__img} src={Bear} alt="Bear" />
            </div>
            <div className={styles.box__right}>
              <p className={styles.box__descr}>
                Детские товары&nbsp;&mdash; это не&nbsp;мгновенная покупка,
                молодые родители супер требовательная аудитория и&nbsp;они
                тщательно подходят к&nbsp;выбору: изучают варианты, кладут
                в&nbsp;корзину, заказывают не&nbsp;сразу. Мы&nbsp;учли этот
                момент и&nbsp;использовали модель атрибуции post click
                7&nbsp;дней, чтобы отследить таких родителей + наша CDP
                позволяет посмотреть LTV пользователя (из-за StableID),
                выгрузить повторные покупки на&nbsp;протяжении длительного
                периода.
              </p>
              <p className={styles.box__mobile__descr}>
                Детские товары&nbsp;&mdash;
                <br /> это не&nbsp;мгновенная покупка,
                <br />
                молодые родители супер требовательная аудитория
                <br /> и&nbsp;они тщательно подходят
                <br /> к&nbsp;выбору: изучают варианты,
                <br /> кладут в&nbsp;корзину, заказывают
                <br /> не&nbsp;сразу.
              </p>
              <p className={styles.box__mobile__descr}>
                Мы&nbsp;учли этот момент
                <br /> и&nbsp;использовали модель
                <br /> атрибуции post click 7&nbsp;дней,
                <br /> чтобы отследить таких
                <br /> родителей + наша CDP
                <br /> позволяет посмотреть LTV
                <br /> пользователя (из-за StableID),
                <br /> выгрузить повторные покупки на&nbsp;протяжении
                длительного
                <br />
                периода.
              </p>
            </div>
          </div>
          <div className={styles.divider}></div>
        </article>
        <section>
          <div className={styles.container}>
            <p className={styles.text}>
              Мы&nbsp;старались не&nbsp;переключать аудиторию
              с&nbsp;маркетплейса на&nbsp;маркетплейс. Работали
              с&nbsp;аудиторией только Wildberries. Это была командная работа
              с&nbsp;клиентом&nbsp;&mdash; держали цену и&nbsp;следили, чтобы
              цена не&nbsp;сильно отличалась на&nbsp;разных маркетплейсах. Если
              вы&nbsp;планируете запускать внешний трафик на&nbsp;Wildberries,
              мы&nbsp;подготовили несколько полезных рекомендаций.
            </p>
            <ol className={styles.section__list}>
              <li className={styles.secton__list__item}>
                Статичные креативы отрабатывают хорошо, а&nbsp;если использовать
                анимацию, то&nbsp;выходит дороже по&nbsp;ставке за&nbsp;клик.
                Поэтому идеальное комбо на&nbsp;маркетплейсах статика+анимация.
              </li>
              <li className={styles.secton__list__item}>
                Лучше тестировать два вида изображений: отдельно товар
                и&nbsp;отдельно товар с&nbsp;детьми.
              </li>
              <li className={styles.secton__list__item}>
                Попробуйте вместо широкий настроек площадок более узкую
                аудиторию, например, неочевидные сегменты сопутствующих товаров
              </li>
            </ol>
            <p className={styles.section__text}>
              В&nbsp;период рекламной компании добились поставленных целей
              и&nbsp;перевыполнили KPI:
            </p>
            <p className={styles.section__descr}>— снизили CPO на 30%</p>
            <p className={styles.section__descr}>— ROAS > 5</p>
            <p className={styles.text}>— CTR = 1,19%</p>
            <p className={`${styles.text} ${styles.last__text}`}>
              С&nbsp;командой Alilo тепло сотрудничаем с&nbsp;2022 года
              и&nbsp;продолжаем помогать создавать спрос на&nbsp;новые продукты
              бренда.
            </p>
          </div>
        </section>
      </div>
      <Form t={t} />
    </Layout>
  )
}

export default Alilo
